const urlParams = new URLSearchParams(window.location.search)

const utmSource = urlParams.get('utm_source') ?? ''
const utmMedium = urlParams.get('utm_medium') ?? ''
const utmCampaign = urlParams.get('utm_campaign') ?? ''
const gclid = urlParams.get('gclid')

const utmCookie = window.Cookies.get('sfStore') || 'eyJ1dG0iOiIiLCJnY2xpZCI6IiJ9'
const encodedUtmCookie = JSON.parse(atob(utmCookie))

if (utmSource || utmMedium || utmCampaign) {
  encodedUtmCookie.utm = encodeURIComponent(`${utmSource}~${utmMedium}~${utmCampaign}`)
}

if (gclid) {
  encodedUtmCookie.gclid = encodeURIComponent(gclid)
}

window.Cookies.set('sfStore', btoa(JSON.stringify(encodedUtmCookie)))
